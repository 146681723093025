.xplenty-content-section {
  .xplenty-content-wrapper {
    //max-width: 742px;
    //margin: 100px auto;
    display: flex;
    //justify-content: space-between;
    > * {
      //flex: 0 0 50%;
    }
  }
  .xplenty-content-container-wrapper {
    position: relative;
    background: linear-gradient(
      100.44deg,
      #f8b67f 0%,
      #ff5451 33.57%,
      #ee1ec0 67.02%,
      #a02cfc 103.97%
    );
    padding: 1px;
    margin-bottom: 15px;
    border-radius: 16px;
  }
  .xplenty-content-container {
    margin: 0;
    z-index: 2;
  }
}
.xplenty-container {
  margin: 100px auto;
}
@media screen and (min-width: 320px) and (max-width: 768px) {
  .xplenty-container {
    margin: 50px auto;
  }
}
.xplenty-content {
  padding: 22px;
  border-radius: 16px;
}
.stats-item {
  margin-top: 1rem;
}
.negative-margin-bottom {
  margin-bottom: -0.5rem;
}
.stats-item-border {
  border-bottom: 1px solid var(--theme-border);
}

.swap-token-select-box-wrapper {
  background: linear-gradient(
    100.44deg,
    #f8b67f 0%,
    #ff5451 33.57%,
    #ee1ec0 67.02%,
    #a02cfc 103.97%
  );
  padding: 1px;
  border-radius: 16px;
  margin-bottom: 15px;
}

.xplenty-content-container-shadow {
  width: 200px;
  background: linear-gradient(
    100.44deg,
    #f8b67f 0%,
    #ff5451 33.57%,
    #ee1ec0 67.02%,
    #a02cfc 103.97%
  );
  filter: blur(60px);
  height: 70px;
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  bottom: 0;
}
.xplenty-heading {
  font-weight: bold;
  font-size: 24px;
  line-height: 34px;
}
.xplenty-info-wrapper {
  padding-right: 30px;
}
.xplenty-info {
  font-size: 12px;
  font-weight: 500;
  line-height: 21px;
}
.xplenty-btn {
  border: none;
}
.xplenty-staking-apr {
  font-weight: 500;
  font-size: 14px;
  line-height: 13px;
  margin: 0;
}
.xplenty-total-balance-label {
  font-weight: bold;
  font-size: 12px;
  line-height: 17px;
  margin: 0;
}
.xplenty-total-balance-wrapper:first-child {
  flex: 0 0 50%;
  border-right: 1px solid rgba(255, 255, 255, 0.24);
}
.xplenty-number-info {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  li {
    padding-left: 24px;
    padding-right: 24px;
    border-right: 1px solid rgba(255, 255, 255, 0.15);
    &:first-child {
      padding-left: 0;
    }
    &:nth-child(3) {
      padding-right: 0;
      border: 0;
    }
    p {
      font-weight: 500;
      font-size: 12px;
      line-height: 14px;
      margin: 0;
    }
    p.xplenty-numbers {
      font-weight: 500;
      font-size: 14px;
      line-height: 14px;
      background: linear-gradient(
        100.44deg,
        #f8b67f 0%,
        #ff5451 33.57%,
        #ee1ec0 67.02%,
        #a02cfc 103.97%
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
}
.max-btn {
  color: #e35f5f;
}
.xplenty-content-section {
  .swap-container-tab {
    .nav-link.active {
      border: 0 !important;
      position: relative;
      &:after {
        content: '';
        width: 100%;
        height: 2px;
        background: linear-gradient(
          100.44deg,
          #f8b67f 0%,
          #ff5451 33.57%,
          #ee1ec0 67.02%,
          #a02cfc 103.97%
        );
        position: absolute;
        left: 0;
        bottom: 0;
      }
    }
  }
}
