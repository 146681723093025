@import './assets/scss/main.scss';

html,
body {
  width: 100%;
  min-height: 100vh;
}
body {
  font-family: 'DM Sans', sans-serif !important;
}
