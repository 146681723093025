.pool-card-top-banner {
  border-radius: 14.5px 14.5px 0px 0px;
  padding: 5px;
  text-align: center;
  margin: -18px -6px 12px;
  &.info {
    background: linear-gradient(135deg, #81fbb8 0%, #28c76f 100%);
  }
  &.alert {
    background: linear-gradient(135deg, #feb692 0%, #ea5455 100%);
  }
}
.pool-card-top-banner-text {
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  color: #ffffff;
  margin: 0;
}
.pool-card-img-wrapper {
  width: 42px;
  margin-right: 12px;
}
